<template>
  <div>
    <el-form ref="editForm" :model="form" label-width="80px" :rules="editFormRules">
      <el-form-item label="机构名" prop="organizationName" style="width: 300px">
        <el-input v-model="form.organization"  label-width="80px"></el-input>
      </el-form-item>
      <el-form-item label="首页图">
        <el-image
          style="width: 100px; height: 100px"
          :src="image"
          :preview-src-list="imagesList"
        ></el-image>
        <el-upload
          :before-upload="beforeUpload" 
          :headers="headers"
          class="upload-demo"
          action="http://129.211.222.48:9091/sys/agent/updateimage"
          :on-success="upImageSuccess"
          :on-error="uploadError"
          style="width: 300px"
          :show-file-list="false"
          
        >

          <el-button size="small" type="primary" >{{
            image_button_name
          }}</el-button>
          <el-button size="small" type="danger" @click.stop="clearImg" v-if="image_button_name!='点击上传'">清空图片</el-button>
        </el-upload>
      </el-form-item>

      <!-- :preview-src-list="srcList" -->
      <el-form-item label="图标">
        <el-image
          style="width: 100px; height: 100px"
          :src="icon"
          :preview-src-list="iconList" 
        ></el-image>

        <el-upload
          :before-upload="beforeUpload" 
          :headers="headers"
          class="upload-demo"
          action="http://129.211.222.48:9091/sys/agent/updateimage"
          :on-success="upIconSuccess"
          :on-error="uploadError"
          :show-file-list="false"
          style="width: 300px"
        >
        <!-- action="http://129.211.222.48:9091/sys/agent/updateimage" -->
        <!-- action="http://localhost:9091/sys/agent/updateimage" -->
          <el-button size="small" type="primary">{{
            icon_button_name
          }}</el-button>
          <el-button size="small" type="danger" @click.stop="clearIcon" v-if="icon_button_name!='点击上传'">清空图片</el-button>
        </el-upload>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click.prevent="onSubmit">确定</el-button>
        <el-button>取消</el-button>
      </el-form-item>

      <el-form-item>
        <p style="color:red">※首页宣传信息页，需填写机构名、上传首页图及图标后，方能在登录时显示</p>
      </el-form-item>
    </el-form>
    
    
  </div>
</template>

<script>
export default {
  name: "Agentmanage",
  data() {
    return {
      image_button_name:"点击上传",
      icon_button_name: "点击上传",
      icon: "http://129.211.222.48:8080/myimages/default.png",
      defaultImg:"http://129.211.222.48:8080/myimages/default.png",
      image: "http://129.211.222.48:8080/myimages/default.png",
      // dialogImageUrl:
      //   "http://47.109.88.39:8080/myimages/1d8cce34-11cc-4c73-8996-8504f33d5a50.png",
      dialogVisible: true,
      form: {
        organization: "",
        image:'',
        icon:''
      },
      imagesList:[],//已上传图片的大图预览信息
      iconList:[],//已上传icon的大图预览信息
      limit: 1,
     // iconFileList: [],//已上传icon文件列表信息
      headers: {
        Authorization: localStorage.getItem("token"), //headers属性中添加token，这个属性是el-upload自带的用来设置上传请求头部
      },
      editFormRules: {
					organizationName: [
						// {required: true, message: '请输入机构名', trigger: 'blur'}
					]
			},
    };
  },
  created() {
    this.getAgentInfo();
  },

  methods: {
    //清空该用户的img数据
    clearImg(){
      //请求后台清空img数据
      this.$axios.put('/sys/agent/clearImg?type=image')
      .then(res => {
        this.$message({
          showClose: true,
          message: '操作成功',
          type: 'success',
          onClose:() => {
            this.clearImgAfter();
          }
        });
      })
      
    },

    //清除该用户的icon
    clearIcon(){
      this.$axios.put('/sys/agent/clearImg?type=icon')
      .then(res => {
        this.$message({
          showClose: true,
          message: '操作成功',
          type: 'success',
          onClose:() => {
            this.clearIconAfter()
          }
        });
      })
      
    },
    clearImgAfter(){
        //清空上传图片后的处理
        this.image = this.defaultImg; //显示为待上传的样式
        this.form.image = ''; 
        this.image_button_name = '点击上传';
        this.imagesList=[];
    },
    clearIconAfter(){

        this.icon = this.defaultImg; //显示为待上传的样式
        this.form.icon = ''; 
        this.icon_button_name = '点击上传';
        this.iconList=[];
    },
    uploadError(err){
      this.$message.error('上传失败')
    },

    beforeUpload(file) {
        // 判断图片是否大于1M
        const isLt1M = file.size /1024/1024 < 2;
        // const fileType = file.name.substring(file.name.lastIndexOf('.')+1)
        // 判断图片的类型
        const isJpg = file.type ==  'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png'
        if(!isJpg){
            this.$message.error('只能上传jpg, jpeg, png格式的图片！')
            return false
        }
        if (!isLt1M) {
            this.$message.error('上传的图片大小不能超过 2MB');
            return false
        }
    },


    //宣传图上传成功
    upImageSuccess(res,file){
      //刷新宣传图显示
      this.image = res.data
      this.image_button_name = '重新上传'
      //设置已上传图片的大图预览
      this.imagesList=[this.image]
    },
    //icon上传成功
    upIconSuccess(res,file){
      //刷新宣传图显示
      this.icon = res.data
      this.icon_button_name = '重新上传'
      this.iconList=[this.icon]
    },
    //提交表单
    onSubmit(){
      this.form.organization    
      this.form.image = this.image
      this.form.icon = this.icon
      console.log('this.form',this.form)
      console.log("submit");

      this.$refs['editForm'].validate((valid) => {
					if (valid) {
						this.$axios.post('/sys/agent/updateagent' , this.form)
							.then(res => {
								this.$message({
									showClose: true,
									message: '操作成功',
									type: 'success',
									onClose:() => {
										this.getAgentInfo()
									}
								});
							})
					} else {
						return false;
					}
				});
    },

    //页面刷新-初始化信息
    getAgentInfo() {
      this.$axios.get("/sys/user/get/operator").then((res) => {
        let tmp = res.data.data;
        //机构名
        this.form.organization = tmp.organization;    
        
        //宣传图
        if (tmp.image != undefined&&tmp.image !=""&&tmp.image !=this.defaultImg) {
          //首页图-显示之前上传的图片
          this.image = tmp.image;
          //“点击上传” --> "重新上传"
          this.image_button_name = "重新上传";
          //大图预览
          this.imagesList=[this.image]
        }
        // //清空/未上传图片时的处理
        // else{
        //   this.image = this.defaultImg; //显示为待上传的样式
        //   this.form.image = ''; 
        //   this.image_button_name = '点击上传';
        //   this.imagesList=[];

        // }

        //图标
        if (tmp.icon != undefined&&tmp.icon !=""&&tmp.icon !=this.defaultImg) {
          //显示之前上传的图标
          this.icon = tmp.icon;
          //“点击上传” --> "重新上传"
          this.icon_button_name = "重新上传";
          //大图预览
          this.iconList=[this.icon]
        }
        // //清空/未上传icon时的处理
        // else{
        //   this.icon = this.defaultImg; //显示为待上传的样式
        //   this.form.icon = ''; 
        //   this.icon_button_name = '点击上传';
        //   this.iconList=[];

        // }
      });
    },


    handleChange(fileList) {
      if (fileList.length >= 1) {
        console.log(">=1 this.$refs.upload", this.$refs.upload);
        //.style.display='none';
      } else {
        this.$refs.upload.$el.querySelector(".el-upload").style.display =
          "none";
      }
      // else{
      // 	this.$refs.upload.$el.querySelector('.el-upload').style.display='inline-block';
      // }
    },

    handleRemove(file) {
      console.log(file);
    },
    // handlePictureCardPreview(file) {
    //   this.dialogImageUrl = file.url;
    //   this.dialogVisible = true;
    // },
    handleDownload(file) {
      console.log(file);
    },
  },
};
</script>

<style scoped>
.el-pagination {
  float: right;
  margin-top: 22px;
}
.el-select-dropdown__item.selected {
  color: #606266;
  font-weight: 100;
}
</style>